import styled from 'styled-components';

const BaseDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ isActive }) => (isActive ? '#4D8EF8' : '#9CBCF7')};
`;

const ExtendedLine = styled(BaseDot)`
  height: ${({ isActive }) => (isActive ? 'auto' : '0px')};
`;

export const Dot = {
  BaseDot,
  ExtendedLine,
};
