import React from 'react';

import { ImageCore, Text } from '@components';
import { Box, Grid } from '@core';

const FeatureCard = (item) => (
  <Box minWidth="100%" p={24}>
    {/* @TODO update image setup, when actual images and designs are provided */}
    <Grid.Col height={160} display="flex" alignItems="center" px={16} py={8}>
      <ImageCore
        src={item.image?.src}
        alt={item.image?.alt || item.title}
        height="100%"
        imgStyle={{ objectFit: 'contain', width: 'auto', maxWidth: '100%' }} // to fix image alignment on safari
      />
    </Grid.Col>
    <Text.Subheading2 my={32}>{item.title}</Text.Subheading2>
    <Text.Body2>{item.description}</Text.Body2>
  </Box>
);

export default FeatureCard;
