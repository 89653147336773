import React from 'react';

import { Box } from '@core';
import { Text } from '@components/typography';

import { Dot } from './features-accordion-dot';

const Header = ({ isOpen, title }) => (
  <Box display="flex" alignItems="baseline">
    <Box m={16}>
      <Dot.BaseDot isActive={isOpen} />
    </Box>
    <Text.Body1Strong color={isOpen ? 'darkBlue' : 'darkBlue_60'} textAlign="left">
      {title}
    </Text.Body1Strong>
  </Box>
);

export default Header;
