import React from 'react';

import { Box } from '@core';
import { Text } from '@components';

import { Dot } from './features-accordion-dot';

const Content = ({ isActive, description }) => (
  <Box display="flex">
    <Box display="flex" flexShrink={0} my={10} mx={16}>
      <Dot.ExtendedLine isActive={isActive} />
    </Box>
    <Text.Body2 textAlign="left">{description}</Text.Body2>
  </Box>
);

export default Content;
