import React from 'react';

import { Box, Button, Slider } from '@core';
import { ArrowLink, DoubleTextButton, LinkWrapper } from '@components';

import { FeatureCard, MoneyBackGuarantee } from './components';

const FeaturesAccordionMobile = ({ data = [], button, link, showGuarantee, onOpenItem }) => (
  // @TODO check how to handle buttons in mobile layout
  <React.Fragment>
    <Slider items={data} itemComponent={FeatureCard} onStepChange={onOpenItem} />
    {/*{button?.link && button?.title && (*/}
    {/*  <Box mt={48} display="flex" flexDirection="column" alignItems="center">*/}
    {/*    <LinkWrapper link={button.link} withParams>*/}
    {/*      {button.subtitle ? (*/}
    {/*        <DoubleTextButton*/}
    {/*          type="button"*/}
    {/*          size="large"*/}
    {/*          width={280}*/}
    {/*          title={button.title}*/}
    {/*          subtitle={button.subtitle}*/}
    {/*          {...button.buttonProps}*/}
    {/*        />*/}
    {/*      ) : (*/}
    {/*        <Button type="button" size="large" width={280} {...button.buttonProps}>*/}
    {/*          {button.title}*/}
    {/*        </Button>*/}
    {/*      )}*/}
    {/*    </LinkWrapper>*/}
    {/*    {showGuarantee && <MoneyBackGuarantee />}*/}
    {/*  </Box>*/}
    {/*)}*/}
    {link?.link && link?.title && (
      <Box mt={8} display="flex" justifyContent="center">
        <ArrowLink link={link.link} customEvent={link?.customEvent}>
          {link.title}
        </ArrowLink>
      </Box>
    )}
  </React.Fragment>
);

export default FeaturesAccordionMobile;
