import React from 'react';

import { Show } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';

import {
  FeaturesAccordionDesktop,
  FeaturesAccordionMobile,
  FeaturesAccordionTablet,
} from './layouts';

const FeaturesAccordion = ({
  title,
  description,
  button,
  link,
  data = [],
  showGuarantee = false,
  onOpenItemDesktop,
  onOpenItemMobile,
}) => (
  <SectionContainer data-section-id="FeaturesAccordion" title={title} description={description}>
    <Show.Mobile>
      <FeaturesAccordionMobile
        data={data}
        button={button}
        link={link}
        showGuarantee={showGuarantee}
        onOpenItem={onOpenItemMobile}
      />
    </Show.Mobile>

    <Show.TabletSmallOnly>
      <FeaturesAccordionTablet
        data={data}
        button={button}
        link={link}
        showGuarantee={showGuarantee}
        onOpenItem={onOpenItemMobile}
      />
    </Show.TabletSmallOnly>

    <Show.TabletLargeAndUp>
      <FeaturesAccordionDesktop
        data={data}
        button={button}
        link={link}
        showGuarantee={showGuarantee}
        onOpenItem={onOpenItemDesktop}
      />
    </Show.TabletLargeAndUp>
  </SectionContainer>
);

export default FeaturesAccordion;
