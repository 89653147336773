import React from 'react';

import { Accordion, AccordionGroup, Box, Button, Grid } from '@core';
import { ArrowLink, DoubleTextButton, ImageCore, LinkWrapper } from '@components';

import { Content, Header, MoneyBackGuarantee } from './components';

const FeaturesAccordionDesktop = ({ data = [], button, link, showGuarantee, onOpenItem }) => {
  const [openItem, setOpenItem] = React.useState(0);

  return (
    <Grid.Container px={32}>
      <Grid.Row>
        <Grid.Col size={6}>
          <AccordionGroup
            accordion={Accordion}
            onOpen={(item) => {
              setOpenItem(item);
              onOpenItem && onOpenItem(item);
            }}
            data={data.map(({ title, description }) => ({
              header: ({ isOpen }) => (
                <Box pt={16} pb={!isOpen && 16}>
                  <Header isOpen={isOpen} title={title} />
                </Box>
              ),
              content: ({ isOpen }) => (
                <Box pb={16}>
                  <Content isActive={isOpen} description={description} />
                </Box>
              ),
            }))}
          />
          {/*{button?.link && button?.title && (*/}
          {/*  <Box mx={16} mt={16}>*/}
          {/*    <LinkWrapper link={button.link} withParams>*/}
          {/*      {button.subtitle ? (*/}
          {/*        <DoubleTextButton*/}
          {/*          type="button"*/}
          {/*          size="large"*/}
          {/*          width={280}*/}
          {/*          title={button.title}*/}
          {/*          subtitle={button.subtitle}*/}
          {/*          {...button.buttonProps}*/}
          {/*        />*/}
          {/*      ) : (*/}
          {/*        <Button type="button" size="large" width={280} {...button.buttonProps}>*/}
          {/*          {button.title}*/}
          {/*        </Button>*/}
          {/*      )}*/}
          {/*    </LinkWrapper>*/}
          {/*    {showGuarantee && <MoneyBackGuarantee />}*/}
          {/*  </Box>*/}
          {/*)}*/}

          {link?.link && link?.title && (
            <Box ml={16} mt={8}>
              <ArrowLink link={link.link} customEvent={link?.customEvent}>
                {link.title}
              </ArrowLink>
            </Box>
          )}
        </Grid.Col>
        {/* @TODO update image setup, when actual images and designs are provided */}
        <Grid.Col size={6} pl={24}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            {data[openItem]?.image && (
              <ImageCore
                src={data[openItem].image.src}
                alt={data[openItem].image.alt || data[openItem].title}
              />
            )}
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default FeaturesAccordionDesktop;
